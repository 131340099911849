<!-- eslint-disable no-unused-vars -->
<script>
// import { ref } from 'vue'

export default {
  name: "orderHeader",
  props: {
    orderInfo: Object,
    type: {
      type: String,
      default: "project",
    },
  },
  computed: {},
  data() {
    return {
      showAll: false,
    };
  },
  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const orderInfo = props.orderInfo;
    // eslint-disable-next-line vue/no-dupe-keys
    return { orderInfo };
  },
  methods: {
    back() {
      this.$router.back(-1);
    },
    jumpMusic(item) {},
    replaceBr(str) {
      // eslint-disable-next-line no-control-regex
      var reg = new RegExp("\n", "g"); // g,表示全部替换。
      return str.replace(reg, " <br> ");
    },
    show() {
      this.showAll = true;
    },
    goLogistics() {
      this.$emit('openapp')
      // if (this.orderInfo.deliver_info.query_type === 2) {
      //   this.$router.push(
      //     `/order/project/logistics/${this.orderInfo.order_id}`
      //   );
      // } else if (this.orderInfo.deliver_info.query_type === 1) {
      //   if (this.orderInfo.deliver_info.post_type === 6) {
      //     location.href = `/digital/detail/${this.orderInfo.goods_info[0].nft_stock_hash}.html`;
      //   } else if (this.orderInfo.deliver_info.post_type === 5) {
      //     this.jumpMusic(this.orderInfo.deliver_info.music_info);
      //   } else if (this.orderInfo.deliver_info.post_type === 1) {
      //     this.$router.push(
      //       `/order/project/logisticsInfo/${this.orderInfo.order_id}?express_name=${this.orderInfo.goods_info[0].express_name}&express_no=${this.orderInfo.goods_info[0].express_no}`
      //     );
      //   }
      // }
    },
    copy(e) {},
  },
  render() {
    const orderInfo = this.orderInfo;
    console.log(orderInfo);
    const orderContent = () => {
      const A = [];

      if (
        (orderInfo.address &&
          (orderInfo.address.address_name ||
            orderInfo.address.address_mobile)) ||
        (orderInfo.address_info &&
          (orderInfo.address_info.address_name ||
            orderInfo.address_info.address_mobile))
      ) {
        const addressInfo = orderInfo.address_info || orderInfo.address;
        const topCenter = [];
        if (addressInfo.address_name && addressInfo.address_name != "") {
          topCenter.push(<span>{addressInfo.address_name}</span>);
        }
        if (addressInfo.address_mobile) {
          topCenter.push(<span>{addressInfo.address_mobile}</span>);
        }
        if (
          addressInfo.province ||
          addressInfo.city ||
          addressInfo.county ||
          addressInfo.street ||
          addressInfo.address
        ) {
          A.push(
            <div class="userInfo">
              <span class="logo"></span>
              <div class="user_cont">
                <p class="top">{topCenter}</p>
                <p>
                  {addressInfo.province}&nbsp;
                  {addressInfo.city}&nbsp;
                  {addressInfo.county}&nbsp;
                  {addressInfo.street}&nbsp;
                  {addressInfo.address}
                </p>
              </div>
            </div>
          );
        } else {
          A.push(
            <div class="userInfo">
              <span class="logo"></span>
              <div class="user_cont">
                <p class="top">{topCenter}</p>
              </div>
            </div>
          );
        }
      }
      if (orderInfo.deliver_info) {
        const B = [];
        if (orderInfo.deliver_info) {
          const C = [];
          const D = [];
          if (orderInfo.deliver_info.title_info.title_1) {
            D.push(
              <div onClick={this.goLogistics}>
                <span>发货信息</span>
                {orderInfo.deliver_info.title_info.title_1}
              </div>
            );
          }
          if (orderInfo.deliver_info.title_info.title_2) {
            D.push(
              <div style="position:relative">
                {orderInfo.deliver_info.title_info.title_2}
              </div>
            );
          }
          C.push(<div class="deliver">{D}</div>);
          if (orderInfo.deliver_info && orderInfo.deliver_info.post_msg) {
            C.push(
              <p>
                <label>卖家备注:</label>
                <span>{this.replaceBr(orderInfo.deliver_info.post_msg)}</span>
              </p>
            );
          }
          const classarr = ["logistics_cont"];
          if (orderInfo.deliver_info.title_info.title_copy) {
            classarr.push("logistics_cont_copy");
          }
          B.push(
            <div class={classarr}>
              {C}
            </div>
          );
        }
        A.push(
          <div class="logistics">
            {(() => {
              const img = require("@assets/images/orderlist/orderDetailsIcon4.png");
              return <img class="musicimg" src={img} />;
            })()}
            {B}
          </div>
        );
      }
      if (A.length) {
        return (
          <div>
            <div class="box">{A}</div>
          </div>
        );
      }
    };
    return [<orderContent class="header_bottom" />];
  },
};
</script>

<style lang="scss" scoped>
.bgnav {
  height: 88px;
  width: 100%;
}
.bg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 360px;
  background: linear-gradient(180deg, #b7f8e6 0%, #f2f2f2 100%);
}
.nav {
  width: 100%;
  height: 88px;
  position: fixed;
  top: 120px;
  &.topApp {
    top: 0;
  }
  display: flex;
  align-items: center;
  .back {
    width: 26px;
    height: 27px;
    position: absolute;
    left: 32px;
    width: 30px;
    height: 30px;
    background: url(https://p6.moimg.net/md/jsheader/back.png) center/cover
      no-repeat;
  }
  .order_status {
    .icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      &.ing {
        background: url("~@assets/images/orderlist/orderDetailsIcon1.png")
          center/cover no-repeat;
      }
      &.warning {
        background: url("~@assets/images/orderlist/orderDetailsIcon3.png")
          center/cover no-repeat;
      }
      &.ok {
        background: url("~@assets/images/orderlist/orderDetailsIcon2.png")
          center/cover no-repeat;
      }
    }
    height: 88px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 40px;
    &::v-deepstrong {
      font-size: 34px;
      height: 34px;
      font-weight: bold;
    }
  }
}
.header_bottom {
  margin: 20px 0 0 0;
  border: 1px solid #dee0e1;
  border-radius: 8px;
  position: relative;
  ::v-deep .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px 8px 0 0;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .iconfont {
    font-size: 1em;
  }
  ::v-deep .logistics {
    width: 100%;
    height: max-content;
    border-top: 1px solid  #DEE0E1;
    padding: 20px;
    display: flex;
    align-items: center;
    position: relative;
    .logo {
      svg {
        // @include absolute_center();
        width: 1em;
        height: 1em;
      }
    }
    .nestmore {
      display: flex;
      justify-content: center;
      align-items: center;
      right: 28px;
      width: 56px;
      min-width: 56px;
      height: 56px;
      background: #f8f8f8;
      border-radius: 28px;
      line-height: 56px;
      text-align: center;
      svg {
        // @include absolute_center();
        width: 30px;
        height: 30px;
      }
    }
    .musicimg {
      width: 24px;
      height: 24px;
      margin-right: 20px;
    }
    .logistics_cont {
      position: relative;
      width: inherit;
      min-width: 0;
      flex: 1;
      &.logistics_cont_copy {
        padding-right: 0;
      }
      .deliver {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div:nth-of-type(1) {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          width: 60%;
          line-height: 24px;
          span {
            font-size: 18px;
            font-family: PingFang SC-中粗体, PingFang SC;
            font-weight: bolder;
            color: #1a1a1a;
            margin-right: 20px;
            line-height: 24px;
          }
        }
        div:nth-of-type(2) {
          height: 24px;
          font-size: 16px;
          font-family: PingFang SC-常规体, PingFang SC;
          font-weight: normal;
          color: #1a1a1a;
          line-height: 24px;
        }
      }
      p {
        width: 100%;
        margin: 0;
        font-weight: 700;
        background: #f8f8f8;
        margin-top: 7px;
        font-size: 16px;
        font-family: PingFang SC-常规体, PingFang SC;
        font-weight: normal;
        color: #1a1a1a;
        line-height: 24px;
        padding-left: 10px;
        min-width: 0;
      }
    }
  }
  ::v-deep .userInfo {
    width: 100%;
    height: max-content;
    position: relative;
    // border-radius: 0 0 24px 24px;
    display: flex;
    align-items: center;
    // padding: 30px 30px 0 24px;
    padding: 20px;
    .logo {
      display: inline-block;
      width: 24px;
      height: 24px;
      overflow: hidden;
      border-radius: 50%;
      background: linear-gradient(120deg, #00d6a6, #21b6c7);
      margin-right: 20px;
      background: url("~@assets/images/orderlist/orderDetailsIcon5.png")
        center/cover no-repeat;
    }
    .user_cont {
      flex: 1;
      position: relative;
      p {
        margin: 0;
        &.top {
          margin-top: 0;
          padding: 0;
          font-size: 18px;
          font-family: PingFang SC-中粗体, PingFang SC;
          line-height: 18px;
          font-weight: bold;
          color: #1a1a1a;
          span {
            padding-left: 20px;
            font-weight: 700;
            &:first-child {
              padding-left: 0px;
            }
          }
        }
        font-size: 16px;
        font-family: PingFang SC-常规体, PingFang SC;
        font-weight: normal;
        color: #7f7f7f;
        line-height: 16px;
        margin-top: 15px;
      }
    }
  }
  ::v-deep .express_message {
    width: 100%;
    padding-top: 24px;
    display: flex;
    .cont {
      position: relative;
      label {
        display: inline-block;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 26px;
        margin-right: 10px;
      }
      p {
        margin: 0;
        color: #7a8087;
        font-size: 26px;
        line-height: 39px;
        i {
          padding-right: 3px;
          display: inline;
        }
        span {
          &.show {
            color: #425eb2;
            padding: 0 10px;
          }
        }
      }
    }
  }
}
</style>
