<!-- eslint-disable no-unused-vars -->
<script>
// import { ref } from 'vue'
import OpenApp from "@@/common/openApp.vue";

export default {
  name: "orderHeader",
  props: {
    orderInfo: Object,
    type: {
      type: String,
      default: "project",
    },
  },
  computed: {},
  data() {
    return {
      showAll: false,
    };
  },
  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const orderInfo = props.orderInfo;
    // eslint-disable-next-line vue/no-dupe-keys
    return { orderInfo };
  },
  methods: {
    app() {
      this.$emit("openapp");
    },
    jumpMusic(item) {
      const config = {
        type: "md://album_list?id=" + item.music_id,
        open: true,
      };
      this.$store.dispatch("currentlLinkUrlAction", config);
    },
    replaceBr(str) {
      // eslint-disable-next-line no-control-regex
      var reg = new RegExp("\n", "g"); // g,表示全部替换。
      return str.replace(reg, " <br> ");
    },
    show() {
      this.showAll = true;
    },
  },
  render() {
    const orderInfo = this.orderInfo;
    const orderStatus = () => {
      let icon;
      if (orderInfo.status > 401) {
        icon = () => <div class="icon ok"></div>;
      }
      switch (orderInfo.status) {
        case 101:
          icon = () => <div class="icon ing"></div>;
          break;
        case 202:
          icon = () => <div class="icon ing"></div>;
          break;
        case 201:
        case 302:
          icon = () => <div class="icon ok"></div>;
          break;
        case 102:
        case 103:
          icon = () => <div class="icon warning"></div>;
          break;
        case 501:
        case 401:
          icon = () => <div class="icon ok"></div>;
          break;
      }
      return (
        <div class="order_status">
          <icon />
          <div class="field">
            <div class="cont">
              <strong>
                {orderInfo.status_name || orderInfo.status_data.status_name}
              </strong>
              {(() => {
                if (orderInfo.status_describe) {
                  return <div>{orderInfo.status_describe}</div>;
                }
              })()}
            </div>
          </div>
          {(() => {
            if (
              (orderInfo.order_btns && orderInfo.order_btns.length) ||
              (orderInfo.status_data &&orderInfo.status_data.btn_list &&
                orderInfo.status_data.btn_list.length)
            ) {
              return (
                <el-button class="caozuo" onClick={this.app}>
                  更多操作
                </el-button >
              );
            }
          })()}
        </div>
      );
    };
    return [<orderStatus />, <OpenApp refs="openapp" />];
  },
};
</script>

<style lang="scss" scoped>
.order_status {
  background: #f8f8f8;
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  ::v-deep .icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    &.ing {
      background: url("~@assets/images/orderlist/orderDetailsIcon1.png")
        center/cover no-repeat;
    }
    &.warning {
      background: url("~@assets/images/orderlist/orderDetailsIcon3.png")
        center/cover no-repeat;
    }
    &.ok {
      background: url("~@assets/images/orderlist/orderDetailsIcon2.png")
        center/cover no-repeat;
    }
  }
  ::v-deep .field {
    strong {
      font-size: 18px;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 18px;
    }
    .cont div {
      font-size: 14px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #7f7f7f;
      line-height: 14px;
      padding-top: 10px;
    }
  }
  ::v-deep .caozuo {
    position: absolute;
    right: 20px;
  }
}
</style>
